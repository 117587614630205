<template>
	<div></div>
</template>

<script>
import ForceLogout from '@/components/login/ForceLogoutModal.vue';
import { SIGNUP_MODAL_STYLE } from '@/utils/constants';

export default {
	name: 'ForceLogout',
	created() {
		this.$modal.show(
			ForceLogout,
			{},
			Object.assign({}, SIGNUP_MODAL_STYLE, { name: ForceLogout.name, clickToClose: false }),
		);
	},
};
</script>
